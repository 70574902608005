.home {
  background-image: url('../../images/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  height: 100vh;
}

.home-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 4em;
  padding-bottom: 1em;
  margin: 0 auto;
  transform: scale(1.1);
}

.home-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Oswald', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  font-weight: 1;
  transform: scaleY(1.3);
}

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 100;
}

.email {
  padding: 5px;
}

.notify-me {
  display: flex;
  padding-top: 0;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

:root {
  --primary: black;
}

.btn {
  padding: 4px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn:hover {
  padding: 2px 9px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

@media screen and (min-width: 768px) {
  .home {
    height: 100vh;
  }
  .home-title {
    font-size: 5rem;
    font-weight: 1;
    transform: scaleY(1.3);
  }

  .home-icon {
    transform: scale(1.2);
    padding-top: 5em;
    padding-bottom: 2em;
  }

  .coming-soon {
    font-size: 3rem;
  }

  .email {
    padding: 10px;
  }

  .notify-me {
    padding-top: 2em;
  }

  .btn {
    padding: 8px 20px;
    font-size: 18px;
  }

  .btn:hover {
    padding: 6px 18px;
  }
}
